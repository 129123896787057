import React, { useState, useRef, useEffect } from "react";
import { AiOutlineClose, AiOutlineMinus } from "react-icons/ai";
import { IoSendSharp } from "react-icons/io5";
import "./Talkbot.css";
import { notification } from "antd";
import { postApiAuth } from "./utils/postApiMethodAuth";

const Talkbot = ({ onClose }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([
    { id: 1, text: "Welcome! How can we assist you today?", sender: "bot" },
  ]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const chatBodyRef = useRef(null);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleSend = async () => {
    if (inputValue.trim()) {
      const newMessage = { id: Date.now(), text: inputValue, sender: "user" };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
      setLoading(true);

      // Call API and handle bot response
      try {
        const baseUrl = "https://chatbot.rightc.fi/";
        const url = "query-rv";
        const fullUrl = `${baseUrl}${url}`;

        const response = await postApiAuth(fullUrl, { prompt: inputValue });
        const botMessage = {
          id: Date.now(),
          text: response.response,
          sender: "bot",
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } catch (error) {
        const errorMessage = {
          id: Date.now(),
          text: "Response not in our database",
          sender: "bot",
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);

        notification.error({
          message: "Error",
          description: error.message || "Something went wrong.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-with-us-container">
      <div className="chat-header">
        <span>Ask RC Vision</span>
        <div className="chat-controls">
          <AiOutlineMinus className="minimize-icon" onClick={toggleMinimize} />
          <AiOutlineClose className="close-chat-icon" onClick={onClose} />
        </div>
      </div>
      {!isMinimized && (
        <>
          <div className="chat-body" ref={chatBodyRef}>
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`chat-message ${
                  msg.sender === "user" ? "user-message" : "bot-message"
                }`}
              >
                {msg.text}
              </div>
            ))}
            {loading && <div className="chat-message bot-message">...</div>}
          </div>
          <div className="message-container">
            <input
              type="text"
              placeholder="Type a message..."
              className="message-input"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSend()}
            />
            <IoSendSharp className="send-button" onClick={handleSend} />
          </div>
        </>
      )}
    </div>
  );
};

export default Talkbot;
