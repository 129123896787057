import React, { useState } from "react";
import {
  FileSearchOutlined,
  TruckOutlined,
  BankOutlined,
  RocketOutlined,
  BarChartOutlined,
  TableOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { GiCargoShip, GiIronHulledWarship } from "react-icons/gi";
import { Button, Layout, Menu } from "antd";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import "./Home.css";
import rclogo from "./Assests/rc-logo.png";
import { RiChatSmile2Fill } from "react-icons/ri";
import Talkbot from "../Talkbot";

const { Header, Content, Sider, Footer } = Layout;

const items = [
  {
    key: "overview",
    icon: <FileSearchOutlined className="side-menu-icon" />,
    label: "Overview",
  },
  {
    key: "shipments",
    icon: <TruckOutlined className="side-menu-icon" />,
    label: "Shipments",
  },
  {
    key: "gross-profit",
    icon: <BankOutlined className="side-menu-icon" />,
    label: "Gross Profit",
  },
  {
    key: "sea-lcl",
    icon: <GiIronHulledWarship className="side-menu-icon" />,
    label: "Sea LCL",
  },
  {
    key: "sea-fcl",
    icon: <GiCargoShip className="side-menu-icon" />,
    label: "Sea FCL",
  },
  {
    key: "air",
    icon: <RocketOutlined className="side-menu-icon" />,
    label: "Air",
  },
  {
    key: "sales",
    icon: <BarChartOutlined className="side-menu-icon" />,
    label: "Sales",
  },
  {
    key: "table",
    icon: <TableOutlined className="side-menu-icon" />,
    label: "Table",
  },
];

const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const username = localStorage.getItem("username");
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleLogout = () => {
    // For Clear all items from localStorage
    // localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("overviewUrl");
    localStorage.removeItem("shipmentsUrl");
    localStorage.removeItem("grossprofiteUrl");
    localStorage.removeItem("sealclUrl");
    localStorage.removeItem("seafclUrl");
    localStorage.removeItem("airUrl");
    localStorage.removeItem("salesUrl");
    localStorage.removeItem("tableUrl");
    localStorage.removeItem("username");
    navigate("/");
  };

  const onMenuClick = ({ key }) => {
    navigate(`/home/${key}`);
  };

  const selectedKey = location.pathname.split("/")[2] || "overview";

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const selectedMenuItem = items.find((item) => item.key === selectedKey);

  return (
    <Layout>
      {!collapsed && (
        <Sider
          className="siderbar"
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          breakpoint="lg"
          collapsedWidth="80"
        >
          <div className="top-cus-logo-section">
            <img className="cus-logo" src={rclogo} alt="Profile" />
          </div>
          <Menu
            defaultSelectedKeys={[selectedKey]}
            mode="inline"
            items={items}
            onClick={onMenuClick}
          />
        </Sider>
      )}
      <Layout>
        <Header className="header">
          <div className="left-buttons">
            <Button
              type="text"
              icon={<MenuFoldOutlined />}
              onClick={toggleCollapsed}
              className="header-icon"
            />
            <span className="cus-sel-menu">
              {selectedMenuItem?.label || ""}
            </span>
          </div>
          <div className="right-buttons">
            <span className="cus-username">{username}</span>
            <Button
              type="text"
              icon={<LogoutOutlined />}
              className="header-icon"
              onClick={handleLogout}
            />
          </div>
        </Header>
        <Content className="content">
          <Outlet />
          <Footer className="footer">
            <div className="footer-icon">
              <RiChatSmile2Fill onClick={toggleChat} />
            </div>
          </Footer>
        </Content>
      </Layout>
      {isChatOpen && <Talkbot onClose={toggleChat} />}
    </Layout>
  );
};

export default Home;
